@font-face {
  font-family: "SVN-Gilroy";
  src: url("assets/fonts/svn-Gilroy/SVN-Gilroy.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/svn-Gilroy/SVN-Gilroy.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("assets/fonts/svn-Gilroy/SVN-Gilroy.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-Gilroy.woff") format("woff"),
    /* Pretty Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-Gilroy.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("assets/fonts/svn-Gilroy/SVN-Gilroy.svg#SVN-Gilroy") format("svg");
  /* Legacy iOS */
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SVN-Gilroy";
  src: url("assets/fonts/svn-Gilroy/SVN-GilroyMedium.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/svn-Gilroy/SVN-GilroyMedium.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("assets/fonts/svn-Gilroy/SVN-GilroyMedium.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroyMedium.woff") format("woff"),
    /* Pretty Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroyMedium.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("assets/fonts/svn-Gilroy/SVN-GilroyMedium.svg#SVN-GilroyMedium") format("svg");
  /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SVN-Gilroy";
  src: url("assets/fonts/svn-Gilroy/SVN-GilroySemiBold.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/svn-Gilroy/SVN-GilroySemiBold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("assets/fonts/svn-Gilroy/SVN-GilroySemiBold.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroySemiBold.woff") format("woff"),
    /* Pretty Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroySemiBold.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("assets/fonts/svn-Gilroy/SVN-GilroySemiBold.svg#SVN-GilroySemiBold") format("svg");
  /* Legacy iOS */
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SVN-Gilroy";
  src: url("assets/fonts/svn-Gilroy/SVN-GilroyBold.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/svn-Gilroy/SVN-GilroyBold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBold.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBold.woff") format("woff"),
    /* Pretty Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBold.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBold.svg#SVN-GilroyBold") format("svg");
  /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SVN-Gilroy";
  src: url("assets/fonts/svn-Gilroy/SVN-GilroyBlack.eot");
  /* IE9 Compat Modes */
  src: url("assets/fonts/svn-Gilroy/SVN-GilroyBlack.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBlack.woff2") format("woff2"),
    /* Super Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBlack.woff") format("woff"),
    /* Pretty Modern Browsers */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBlack.ttf") format("truetype"),
    /* Safari, Android, iOS */
    url("assets/fonts/svn-Gilroy/SVN-GilroyBlack.svg#SVN-GilroyBlack") format("svg");
  /* Legacy iOS */
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

* {
  font-family: "SVN-Gilroy", sans-serif !important;
}

.ant-cascader-menu {
  height: auto !important;
}