.ant-notification-notice{
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.ant-notification-notice-message{
    text-align: center;
}
